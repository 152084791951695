import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    // show error to user
    const maintenanceElement = document.getElementById('errorMaintenance');
    const globalErrorElement = document.getElementById('errorGlobal');
    const loader = document.getElementById('loader');
    const requestId = document.getElementsByClassName('request-id')[0];

    const loaderStyle = loader?.style;
    const errorMsgElementStyle = maintenanceElement?.style;
    const globalErrorElementStyle = globalErrorElement?.style;

    if (err?.error?.status?.code === 'MAINTENANCE_EXCEPTION' && loaderStyle && errorMsgElementStyle) {
      loaderStyle.display = 'none';
      errorMsgElementStyle.display = 'flex';
    }
    if (err?.error?.status?.code !== 'MAINTENANCE_EXCEPTION' && loaderStyle && globalErrorElementStyle) {
      loaderStyle.display = 'none';
      const errorRequestId = err?.error?.status?.requestId;
      if (typeof errorRequestId !== 'undefined' && errorRequestId !== null) {
        requestId.innerHTML = `Request ID: ${err?.error?.status?.requestId}`;
      }
      globalErrorElementStyle.display = 'flex';
    }
  });
