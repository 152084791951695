import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '@lms/shared/models';
import { CurrentUserStorage } from '@lms/shared/storages';
import { CookieyesService } from '@lms/shared/services/app-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  user$: Observable<UserModel | null> = this.currentUser.currentUser$;
  constructor(
    private currentUser: CurrentUserStorage,
    private cookieyesService: CookieyesService,
  ) {
    this.cookieyesService.run();
  }
}
