import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptorService } from '@lms/shared/base-api';
import { FormValidationInterceptor } from '@lms/shared/form-validation';
import { EnumsDictionaryModel, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { DictionaryService } from '@lms/shared/services/api-services';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgxStripeModule } from 'ngx-stripe';
import { Observable } from 'rxjs';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiTrackersModule } from '@lms/shared-ui/trackers';
import { GlobalErrorHandler } from '@lms/shared/services/app-services';

export function initializeDictionary(dictionaryService: DictionaryService) {
  return (): Observable<EnumsDictionaryModel> => {
    return dictionaryService.getEnumsDictionary();
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import('@lms/dashboard-ui/layout').then(m => m.DashboardUiLayoutModule),
        },
      ],
      {
        bindToComponentInputs: true,
        enableTracing: false,
      },
    ),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    HammerModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    ServiceWorkerModule.register(environment.serviceWorker ? 'ngsw-worker.js' : 'safety-worker.js'),
    SharedUiTrackersModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FormValidationInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaClientKey,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeDictionary,
      deps: [DictionaryService],
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({
        enabled: environment.serviceWorker,
        registrationStrategy: 'registerImmediately',
      }),
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
